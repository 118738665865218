<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br><br />
    <v-card class="card">
      <v-card-title class="heading justify-center">Add Periodical Report</v-card-title>
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <v-col>
              <label class="add-text">Periodic Series </label>
              <v-autocomplete v-model="series" placeholder="Select Series" :items="series_data" item-text="name"
                item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
            </v-col>
            <v-col>
              <label>Start Date</label>

              <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                full-width max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field class="text" v-model="startDate" placeholder="Select Start Date" append-icon="event"
                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title @input="fromDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label>End Date</label>

              <v-menu v-model="fromDateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y
                full-width max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field class="text" v-model="endDate" placeholder="Select End Date" append-icon="event"
                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title @input="fromDateMenu1 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="dailyData1">Fetch Data</v-btn>
            </center>
          </div>
        </div>
        <v-data-table :headers="headers" :search="search" :items="tabledata" class="elevation-5 ma-2">

          <template v-slot:item.date_of_purchase="{ item }">
            <div v-if="item.date_of_purchase != ''">
              {{ item.date_of_purchase.split("T")[0].split("-")[2] + "-" +
                item.date_of_purchase.split("T")[0].split("-")[1] + "-" + item.date_of_purchase.split("T")[0].split("-")[0]
              }}
            </div>
          </template>
          <template v-slot:item.subscription_from="{ item }">
            <div v-if="item.subscription_from != ''">
              {{ item.subscription_from.split("T")[0].split("-")[2] + "-" +
                item.subscription_from.split("T")[0].split("-")[1] + "-" +
                item.subscription_from.split("T")[0].split("-")[0] }}
            </div>
          </template>
          <template v-slot:item.subscription_to="{ item }">
            <div v-if="item.subscription_to != ''">
              {{ item.subscription_to.split("T")[0].split("-")[2] + "-" + item.subscription_to.split("T")[0].split("-")[1]
                + "-" + item.subscription_to.split("T")[0].split("-")[0] }}
            </div>
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>Add Periodical Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <download-excel class="ma-3" v-if="tabledata.length != 0" color="primary" dark :data="tabledata"
                :fields="fields" worksheet="My Worksheet" name="periodical_report.xls">
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>

        </v-data-table>

      </div>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({

    dept: null,
    fromDateMenu1: false,
    usertype: null,
    valid: false,
    overlay: false, items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Reports',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'Add Periodical Report',
        disabled: true,
        href: '',
      },
    ],
    userid: "",
    bookitemid: "",
    selected: [],
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    org: "",
    dialog: false,
    fromDateMenu: false,
    fromDateVal: [],
    startDate: "",
    endDate: "",
    dailyData: [],
    program_data: [],
    usertype_data: [],
    minDate: "2020-01-05",
    maxDate: new Date(),
    program: "",
    no_of_days: 30,
    purchasedate: "",
    fields: {

      Sr_no: "srno",
      Title: "title",
      Publisher: "publisher",
      Source: "source",
      Frequency: "frequency",
      Volume_no: "volume_no",
      Issue_no: "issue_no",
      Department: "department",
      Type: "type",
      Date_of_purchase: "date_of_purchase",
      Subscription_from: "subscription_from",
      Subscription_To: "subscription_to"
    },
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Source",
        align: "left",
        sortable: true,
        value: "source",
      },
      {
        text: "Frequency",
        align: "left",
        sortable: true,
        value: "frequency",
      },
      {
        text: "Volume No",
        align: "left",
        sortable: true,
        value: "volume_no",
      },
      {
        text: "Issue No",
        align: "left",
        sortable: true,
        value: "issue_no",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "department",
      },
      {
        text: "Type",
        align: "left",
        sortable: true,
        value: "type",
      },
      {
        text: "Date Of Purchase",
        align: "left",
        sortable: true,
        value: "date_of_purchase",
      },
      {
        text: "Subscription From",
        align: "left",
        sortable: true,
        value: "subscription_from",
      },
      {
        text: "Subscription To",
        align: "left",
        sortable: true,
        value: "subscription_to",
      },
    ],
  }),
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
    this.endDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.onLoad();
  },
  methods: {
    dailyData1() {
      this.overlay = true;

      const data = {
        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
        series_id: this.series,
      };
      axios
        .post("/Librarian/periodics_report_data", data)
        .then((res) => {
          if (res.data.msg == '200') {
            this.overlay = false;
            if (res.data.pdata) {
              this.tabledata = res.data.pdata;
            } else {
              this.overlay = false;
              this.tabledata = [];
              this.showSnackbar("#b71c1c", "Data Not Available");
            }
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          this.tabledata = [];
          this.overlay = false;
          window.console.log(error);
        })
        .finally(() => { });
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getPeriodicalReportList")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
            const today = new Date();
            const previousdate = new Date();
            previousdate.setDate(today.getDate() - this.no_of_days);
            this.startDate = moment(String(previousdate)).format("YYYY-MM-DD");
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}</style>
